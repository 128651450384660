<template>
  <div v-if="EditItem">
    <el-dialog title="学生访谈记录详情" :visible.sync="dialogshow" :close-on-click-modal="false" :modal-append-to-body="false"
      :destroy-on-close="true" @close="closeDialog" width="800px" custom-class="cus_dialog">
      <div>
        <el-form :model="EditItem" ref="EditItem" label-width="120px" v-if="EditItem">
          <el-row style="padding-top: 10px;">
            <el-col :span="10">
              <el-form-item class="is-required" label="学号">
                <el-select v-model="EditItem.stu_num" :disabled="dialogxx" filterable remote reserve-keyword
                  placeholder="请输入学号" :remote-method="remoteMethod" :loading="loading" style="width:180px"
                  @change="checkedxz" @focus="focusxz">
                  <el-option v-for="item in options" :key="item.value" :label="item.label"
                    :value="item.value"></el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="10">
              <el-form-item label="学生姓名">
                <el-input v-model="EditItem.stu_name" disabled style="width:180px"></el-input>
              </el-form-item>
            </el-col>
          </el-row>

          <el-row style="padding-top: 10px;">
            <el-col :span="10">
              <el-form-item label="班级名称">
                <el-input v-model="EditItem.class_name" disabled style="width:180px"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="10">
              <el-form-item label="家长姓名">
                <el-input v-model="EditItem.parent_name" :disabled="disabled" style="width:180px"></el-input>
              </el-form-item>
            </el-col>
          </el-row>

          <el-row style="padding-top: 10px;">
            <el-col :span="10">
              <el-form-item class="is-required" label="访谈方式">
                <el-select v-model="EditItem.contact_type" filterable placeholder="请选择访谈方式" style="width:180px"
                  :disabled="disabled">
                  <el-option v-for="(item, idx) in ftfsOptions" :key="idx" :label="item.name" :value="item.id"
                    filterable:true></el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="10">
              <el-form-item label="访谈时间">
                <el-date-picker class="is-required" v-model="EditItem.contact_time" :disabled="disabled" type="datetime"
                  placeholder="选择访谈时间" format="yyyy-MM-dd HH:mm:ss" value-format="yyyy-MM-dd HH:mm:ss"></el-date-picker>
              </el-form-item>
            </el-col>
          </el-row>
          <!-- <el-form-item label="访谈内容">
            <el-col :span="22">
              <div style="line-height: initial; width: 100%; ">
                <UEditor ref="ue" @ready="editorReady" style="width: 100%; "></UEditor>
              </div>
            </el-col>
          </el-form-item> -->
          <el-form-item class="is-required" label="访谈内容">
            <el-input @input="$forceUpdate()" type="textarea" v-model="EditItem.content" :autosize="{ minRows: 3 }"
              placeholder="选输入访谈内容" ></el-input>
          </el-form-item>
          <el-row style="padding-top: 10px;">
            <el-col :span="10">
              <el-form-item label="排序号">
                <el-input-number v-model="EditItem.sort" :disabled="disabled" controls-position="right"
                  style="width:180px"></el-input-number>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>

        <el-row>
          <el-col :span="22" style="text-align: right;padding-left: 10px;"
            v-if="$store.state.teacher.id == EditItem.create_by || EditItem.id == 0">
            <el-button size="small" icon="el-icon-edit" type="primary" @click="submitForm()">提交</el-button>
          </el-col>
        </el-row>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import util from "../../utils/util.js";
import UEditor from "../notify/ueditor.vue";
export default {
  components: { UEditor },
  data() {
    return {
      editor: null,
      dialogshow: true,
      dialogxx: true,
      disabled: true,
      optionsRow: {
        stu_code: "",
        stu_id: "",
        stu_name: "",
        class_name: ""
      },
      EditItem: {
        is_draft: 1,
        title1: "",
        id: 0,
        stu_id: 0,
        stu_code: "",
        stu_name: "",
        class_name: "",
        parent_name: "",
        contact_type: "",
        contact_time: "",
        content: "",
        create_by: 0,
        create_name: "",
        sort: 99
      },
      pickerOptions: {
        shortcuts: [
          {
            text: "今天",
            onClick(picker) {
              picker.$emit("pick", new Date());
            }
          },
          {
            text: "昨天",
            onClick(picker) {
              const date = new Date();
              date.setTime(date.getTime() - 3600 * 1000 * 24);
              picker.$emit("pick", date);
            }
          },
          {
            text: "一周前",
            onClick(picker) {
              const date = new Date();
              date.setTime(date.getTime() - 3600 * 1000 * 24 * 7);
              picker.$emit("pick", date);
            }
          }
        ]
      },
      options: [],
      optionsRow: [],
      ftfsOptions: [
        { id: 1, name: "电话" },
        { id: 2, name: "视频" },
        { id: 3, name: "面谈" },
        { id: 4, name: "QQ" },
        { id: 5, name: "微信" }
      ],
      list: [],
      loading: false
    };
  },
  mounted() {
    this.$$parent(this, "EditItem").then(res => {
      if (res.id == 0) {
        res.contact_time = this.$moment().format("YYYY-MM-DD HH:mm:ss");
        this.disabled = false;
        this.dialogxx = false;
      } else {
        res.contact_time = this.$moment().format("YYYY-MM-DD HH:mm:ss");
        this.options = [{ id: res.stu_id, label: res.stu_code }];
        if (this.$store.state.teacher.id == res.create_by) {
          this.disabled = false;
          res.is_draft = 1;
        } else {
          //修改不是自己的
        }
        setTimeout(() => {
          this.editor.setContent(this.EditItem.content);
        }, 500);
      }

      this.EditItem = res;
      console.log(this.EditItem);
    });
  },
  methods: {
    remoteMethod(query) {
      if (query !== "") {
        this.loading = true;
        this.$http
          .post("/api/students_class_info", { stu_code: query })
          .then(res => {
            this.loading = false;
            if (res.data) {
              this.options = [{ value: res.data.id, label: res.data.stu_name }];
              this.optionsRow.stu_code = res.data.stu_code;
              this.optionsRow.stu_id = res.data.id;
              this.optionsRow.stu_name = res.data.stu_name;
              this.optionsRow.class_name = res.data.class_name;
            }
          });
      } else {
        this.options = [];
      }
    },
    editorReady(editorInstance) {
      editorInstance.setContent(this.EditItem.content);
      editorInstance.addListener("contentChange", () => {
        this.EditItem.content = editorInstance.getContent();
      });
      this.editor = editorInstance;
    },
    checkedxz() {
      this.dialogxx = false;
      this.EditItem.stu_code = this.optionsRow.stu_code;
      this.EditItem.stu_id = this.optionsRow.stu_id;
      this.EditItem.stu_name = this.optionsRow.stu_name;
      this.EditItem.class_name = this.optionsRow.class_name;
    },
    focusxz() {
      this.options = [];
      this.optionsRow.stu_code = "";
      this.optionsRow.stu_id = "";
      this.optionsRow.stu_name = "";
      this.optionsRow.class_name = "";
    },
    closeDialog() {
      this.$$parent(this, "dialogshow", false);
    },
    submitForm(st) {
      let data = JSON.parse(JSON.stringify(this.EditItem));
      
      if (!data.stu_id) {
        this.$message.error("学生必选!");
        return false;
      }
      if (!data.contact_type) {
        this.$message.error("访谈方式必选!");
        return false;
      }
      if (!data.contact_time) {
        this.$message.error("访谈时间必选!");
        return false;
      }
      if (data.content.length < 50) {
        this.$message.error("内容必须大于50字!");
        return false;
      }
      this.$http.post("/api/stu_liaison_edit", data).then(res => {
        this.$message({
          type: "success",
          message: "提交成功"
        });
        this.$$parent(this, "getList");
        this.$$parent(this, "dialogshow", false);
      });
    }
  }
};
</script>

<style scoped>
.imgaddbtn {
  width: 100px;
  height: 100px;
  line-height: 100px;
  text-align: center;
  border: 1px dotted #ccc;
  font-size: 30px;
  cursor: pointer;
}

.imgremove {
  width: 20px;
  height: 20px;
  line-height: 20px;
  text-align: center;

  cursor: pointer;
  position: absolute;
  top: -5px;
  right: -5px;
  color: #ccc;
}

.imgremove:hover {
  color: orange;
}
</style>
